/**
 * @description 四捨五入
 * @param val
 * @param precision
 * @returns {string}
 */
export function roundDecimal(val, precision) {
  if (typeof val === 'string') return val
  if (typeof val === 'undefined') return val
  if (val === null) return val

  return (
    Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
    Math.pow(10, precision || 0)
  )
}

export function systolicLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 90 && val <= 139) level = 0
  if ((val >= 30 && val <= 89) || (val >= 140 && val <= 179)) level = 1
  if (val >= 180) level = 2
  return level
}

export function diastolicLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 60 && val <= 89) level = 0
  if ((val >= 30 && val <= 59) || (val >= 90 && val <= 109)) level = 1
  if (val >= 110) level = 2
  return level
}

export function ppLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 21 && val <= 59) level = 0
  if (val >= 60 || val <= 20) level = 2
  return level
}

export function pulseLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 60 && val <= 100) level = 0
  if ((val >= 40 && val <= 59) || (val >= 101 && val <= 149)) level = 1
  if (val >= 150) level = 2
  return level
}

export function fastingLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 70 && val <= 99) level = 0
  if (val <= 69 || (val >= 100 && val <= 125)) level = 1
  if (val >= 126 && val <= 600) level = 2
  return level
}

export function beforeMealLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 70 && val <= 99) level = 0
  if (val <= 69 || (val >= 100 && val <= 125)) level = 1
  if (val >= 126 && val <= 600) level = 2
  return level
}

export function afterMealLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 70 && val <= 180) level = 0
  if (val <= 69 || (val >= 181 && val <= 199)) level = 1
  if (val >= 200 && val <= 600) level = 2
  return level
}

export function spo2Level(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 95 && val <= 100) level = 0
  if (val >= 90 && val <= 94) level = 1
  if (val >= 0 && val <= 89) level = 2
  return level
}

export function actLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 1 && val <= 3) level = 0
  if (val == 4) level = 1
  if (val == 5 || val == 6) level = 2
  return level
}

export function bodyTemperatureLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 36.0 && val <= 37.0) level = 0
  if (val < 36.0 || (val > 37.0 && val <= 38.0)) level = 1
  if (val > 38.0 && val < 42.2) level = 2
  return level
}

export function bmiLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 18.5 && val <= 24.9) level = 0
  if (val < 18.5 || (val > 24.9 && val <= 29.9)) level = 1
  if (val > 29.9) level = 2
  return level
}

export function visceralFatLevel(val) {
  let level = 0
  if (typeof val === String || val === null || val === undefined) return level

  if (val >= 1 && val <= 9) level = 0
  if (val >= 10 && val <= 14) level = 1
  if (val >= 15 && val <= 30) level = 2
  return level
}
